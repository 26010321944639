import React, { useCallback, useEffect, useState } from 'react';
import { Floppy, Printer, Send } from 'react-bootstrap-icons';
import { Flex, ProgressCircle, Radio, RadioGroup, View } from '@adobe/react-spectrum';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../state/store';
import { useDependency } from '../../../../../../contexts/DependencyProvider';
import { ListFPATypeDocumentParsRequest } from '../../../../../../services/soap/project/requests/ListFPATypeDocumentParsRequest';
import { ListLanguagesRequest } from '../../../../../../services/soap/translate/reqeusts/ListLanguagesRequest';
import { PrintContextRequest } from '../../../../../../services/rest/print/requests/PrintContextRequest';
import { Col, FilterCol, Order } from '../../../../../../services/soap/project/requests/ListFPARequest';
import { useReefConfig } from '../../../../../../hooks/UseReefConfig';
//import './print_diallog.css';
import styles from './print_dialog.module.css';
import { PrintDataRequest } from '../../../../../../services/rest/print/requests/PrintDataRequest';
import { getDownloadUrl } from '../../../../../../utils/ImagePathUtils';
import { openPdfContentInNewWindow, openUrlInNewWindow } from '../../../../../../utils/NavigationUtils';

interface PrintDialogProps {
  open?: boolean;
  onClose?: () => void;
  onSend?: () => void;
  onPrint?: () => void;
  onSave?: () => void;
  selectedItem?: any;
  print_type: string;
}

const PrintDialog: React.FC<PrintDialogProps> = ({ open, onClose, onSend, onPrint, onSave, selectedItem, print_type }) => {
  const formSignatures = useSelector((state: RootState) => state.userWebLayout.formSignatures);
  const { store, projectService, translateService, printRestService } = useDependency();
  const { baseUrl, print_service } = useReefConfig();
  const [docTemplates, setDocTemplates] = useState<any>([]);
  const [langList, setLangList] = useState<any>([]);
  const [langName, setLangName] = useState<string>('');
  const [docType, setDocType] = useState<string>('pdf');
  const [selectDocumentID, setSelectDocumentID] = useState<any>('');
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await loadDocumentParams();
      await loadLanguage();
      setShowLoader(false);
    })();
  }, []);

  const loadDocumentParams = useCallback(async () => {
    let resDocPars = await projectService.listFPATypeDocumentPars(
      new ListFPATypeDocumentParsRequest(store.Server, store.SessionId, {
        limit: 50,
        offset: 0,
        FILTER: [new FilterCol('type', 'equals', selectedItem?.item_type_id)],
        ORDER: new Order([new Col('priority'), new Col('description')]),
      })
    );
    //console.info('defaultDocument', resDocPars);
    if (resDocPars.result === 'OK' && resDocPars?.FPA_TYPE_DOCUMENT_PAR?.length > 0) {
      let docList: any = resDocPars.FPA_TYPE_DOCUMENT_PAR;
      //console.log('docList', docList);
      docList.unshift({
        id: 0,
        description: 'System default',
      });
      const defaultDocument = resDocPars.FPA_TYPE_DOCUMENT_PAR.find((doc: any) => doc.isDefault === 'true');

      setDocTemplates(resDocPars.FPA_TYPE_DOCUMENT_PAR);
      setSelectDocumentID(String(defaultDocument?.document));
    }
  }, [selectedItem]);

  const loadLanguage = useCallback(async () => {
    let langResp = await translateService.listLanguages(new ListLanguagesRequest(store.Server, store.SessionId));
    if (langResp.result === 'OK' && langResp?.LANGUAGE?.length > 0) {
      const filterList: any = langResp.LANGUAGE.filter((lang: any) => lang.useForTranslate === '1');
      filterList.unshift({
        languageShort: '',
        languageLong: 'Default',
      });
      //console.log('langList', filterList);
      setLangList(filterList);
    }
  }, [selectedItem]);

  const handleSendClick = async () => {
    if (isSending || isPrinting || isSaving) return;
    setIsSending(true);
    try {
      await onSend?.();
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsSending(false);
    }
  };

  const onPrintClick = () => {
    if (isSending || isPrinting || isSaving) return;
    setIsPrinting(true);

    if (docType === 'template') {
      let printDataRequest = new PrintDataRequest(store.Server, store.SessionId, {
        print_type: selectedItem.type,
        object_id: selectedItem.id,
        template_id: selectDocumentID,
        language: langName,
      });

      let printData = printRestService.printDataTemplate(printDataRequest);
      let windowReference: any = window.open('', '_blank', 'scrollbars=yes,status=yes,menubar=no,toolbar=no');
      windowReference?.location.replace(`${print_service.uri}${printData}`);
      setIsPrinting(false);
      return;
    }

    if(docType === 'json') {
      let printDataBody = {
        print_type: selectedItem.type,
        object_id: selectedItem.id,
        language: langName,
        signatures: formSignatures,
      }

      printRestService.printData(new PrintDataRequest(store.Server, store.SessionId, printDataBody), { signatures: formSignatures })
      .then(async (response: any) => {
        var data_response = response.data;
        var signatures_data = data_response.signatures;
        var jsonString = JSON.stringify(data_response, null, 2);
        if(signatures_data) {
          jsonString = jsonString.split('"signatures": {}').join(`"signatures": ${JSON.stringify(signatures_data, null, 2)}`);
        }
        let windowReference: any = window.open('', '_blank', 'scrollbars=yes,status=yes,menubar=no,toolbar=no');
        windowReference?.document.write(`
          <!DOCTYPE html>
        <html>
        <head>
            <title>JSON Data</title>
            <style>
                body { font-family: monospace; }
            </style>
        </head>
        <body>
            <pre>${jsonString}</pre>
        </body>
        </html>
        `);
      })
      .catch(error => {
        console.log('error', error);
      })
      .finally(() => {
        setIsPrinting(false);
      });
    } else {
      printRestService
        .printContext(new PrintContextRequest(store.Server, store.SessionId, print_type, String(selectedItem?.id), docType, { signatures: formSignatures }, ``, selectDocumentID))
        .then(async (response: any) => {
          openPdfContentInNewWindow(response);
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          setIsPrinting(false);
          setShowLoader(false);
        });
    }
  };

  const onSaveClick = () => {
    if (isSending || isPrinting || isSaving) return;
    if (docType === 'template') {
      return;
    }
    setIsSaving(true);
    printRestService
        .printDocument(new PrintContextRequest(store.Server, store.SessionId, print_type, String(selectedItem?.id), docType, { signatures: formSignatures }, ``, selectDocumentID))
        .then(async (response: any) => {
          let strURL = getDownloadUrl(response.documentId, baseUrl, store.Server, store.SessionId);
          openUrlInNewWindow(strURL);
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          setIsSaving(false);
          setShowLoader(false);
        });
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <View width={'100%'} position={'relative'} height={'auto'} UNSAFE_className={styles.print_dialog_parent}>
        {docTemplates.length > 0 && (
          <>
            <Flex position={'relative'} height={'30px'} UNSAFE_className={styles.doc_template_heading} alignContent={'center'} alignItems={'center'} justifyContent={'start'}>
              Document template
            </Flex>
            <Flex direction={'row'} position={'relative'} alignItems={'center'} justifyContent={'start'} gap={'32px'}>
              <RadioGroup label="" defaultValue={selectDocumentID} onChange={(value: any) => setSelectDocumentID(value)} orientation="vertical">
                {docTemplates.map((docTemplate: any) => (
                  <Radio key={docTemplate.id} value={String(docTemplate.document)}>
                    {docTemplate.description}
                  </Radio>
                ))}
              </RadioGroup>
            </Flex>
          </>
        )}

        <Flex position={'relative'} height={'30px'} UNSAFE_className={styles.doc_template_heading} alignContent={'center'} alignItems={'center'} justifyContent={'start'}>
          Document type
        </Flex>
        <Flex direction={'row'} position={'relative'} alignItems={'center'} justifyContent={'start'} gap={'32px'}>
          <RadioGroup label="" orientation="horizontal" defaultValue={'pdf'} onChange={value => setDocType(value)}>
            <Radio value="pdf">PDF</Radio>
            <Radio value="docx">Word doc</Radio>
            <Radio value="xlsx">Excel doc</Radio>
            <Radio value="json">JSON</Radio>
            <Radio value="template">Template</Radio>
          </RadioGroup>
        </Flex>

        <Flex position={'relative'} height={'30px'} UNSAFE_className={styles.doc_template_heading} alignContent={'center'} alignItems={'center'} justifyContent={'start'}>
          Document language
        </Flex>
        <Flex direction={'row'} position={'relative'} alignItems={'center'} justifyContent={'start'} gap={'32px'}>
          <RadioGroup label="" orientation="horizontal" onChange={value => setLangName(value)} defaultValue={''}>
            {langList.map((lang: any) => (
              <Radio key={lang.languageShort} value={lang.languageShort}>
                {lang.languageLong}
              </Radio>
            ))}
          </RadioGroup>
        </Flex>

        <View position={'relative'} bottom={'0px'} left={'0px'} width={'100%'} height={'55px'} UNSAFE_className={styles.footer}>
          <Flex direction={'row'} position={'absolute'} bottom={'0px'} left={'calc(50% - 150px)'} alignItems={'start'} justifyContent={'start'} gap={'10px'}>
            <Flex direction={'row'} width={'90px'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} gap={'8px'} 
              UNSAFE_className={`${styles.footer_btn} ${(isSending || isPrinting || isSaving) ? styles.disabled : ''}`}>
              <div style={{ display: 'contents' }} onClick={handleSendClick}>
                {isSending ? (
                  <ProgressCircle size="S" aria-label="Sending..." isIndeterminate />
                ) : (
                  <Send size={32} />
                )}
                <View UNSAFE_className={styles.btn_label}>Send</View>
              </div>
            </Flex>
            <Flex direction={'row'} width={'90px'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} gap={'8px'} 
              UNSAFE_className={`${styles.footer_btn} ${(isSending || isPrinting || isSaving) ? styles.disabled : ''}`}>
              <div style={{ display: 'contents' }} onClick={onPrintClick}>
                {isPrinting ? (
                  <ProgressCircle size="S" aria-label="Printing..." isIndeterminate />
                ) : (
                  <Printer size={16} />
                )}
                <View UNSAFE_className={styles.btn_label}>Print</View>
              </div>
            </Flex>
            <Flex direction={'row'} width={'90px'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} gap={'8px'} 
              UNSAFE_className={`${styles.footer_btn} ${(isSending || isPrinting || isSaving) ? styles.disabled : ''}`}>
              <div style={{ display: 'contents' }} onClick={onSaveClick}>
                {isSaving ? (
                  <ProgressCircle size="S" aria-label="Saving..." isIndeterminate />
                ) : (
                  <Floppy size={32} />
                )}
                <View UNSAFE_className={styles.btn_label}>Save</View>
              </div>
            </Flex>
          </Flex>
        </View>
      </View>
    );
  }
};

export default PrintDialog;
