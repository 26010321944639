import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { 
  Filter, 
  Plus, 
} from 'react-bootstrap-icons';
import styles from './contact_detail_view_mobile.module.css';
import { ContactIcon, EditIcon, FacebookIcon, LinkdinIcon, MoreIcon, TrashIcon, TwitterIcon } from '../../../../components/protected/Icons/IconsLib';
import { ActionButton, Divider, Flex, Heading, Item, Link, ListView, TextField, View } from '@adobe/react-spectrum';
import moment from 'moment';
import { invokeFinderApp } from '../../../../utils/NavigationUtils';
import { usePreloadAssets } from '../../../../hooks/UsePreloadAssets';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { truncateText } from '../../../../utils/helperUtil';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';

const ContactDetailView = ({
  contact,
  contactDataRow,
  listOrganizationFolder,
  employees,
  contactStatusList,
  handleEmployeeClick,
  handleEditClick}:any) => {
  const address = contact.ADDRESSES?.ADDRESS || undefined;
  const {contactSettings} = usePreloadAssets();
  const [employeesData,setEmployeesData] = useState<any>([]);
 
  useEffect(()=>{
    setEmployeesData(employees);
  },[employees])

  const handleFolderClick = (folderId:any)=>{
    invokeFinderApp(folderId)
  }

  const handleEmployeeListClick = (id:any)=>{
    const filteredEmployee = employeesData.find((x:any)=>x.id === id)
    handleEmployeeClick(filteredEmployee.contact)
  }
  
// Required fields to always display
const requiredFields = ["Mobil", "E-mail", "Telefon"];
const requiredIcons = ["Facebook", "Twitter", "Linkedin"];

const getContactDetailsUI = (contact: any): JSX.Element => {
  const contactDetails: Record<string, { value: string | undefined; className: string }> = {};

  const contactsArray: any[] = contact.CONTACTS?.CONTACT
    ? Array.isArray(contact.CONTACTS.CONTACT)
      ? contact.CONTACTS.CONTACT
      : [contact.CONTACTS.CONTACT]
    : [];

  contactsArray.forEach((c) => {
    const typeInfo = contactSettings[c.type];
    if (typeInfo) {
      contactDetails[typeInfo.name] = { value: c.content, className: typeInfo.className };
    }
  });

  contactDetails["Mobil"] = { value: contact.mobile || contactDetails["Mobil"]?.value, className: "mobile-parent" };
  contactDetails["E-mail"] = { value: contact.email || contactDetails["E-mail"]?.value, className: "e-mail-parent" };
  contactDetails["Telefon"] = { value: contact.phone || contactDetails["Telefon"]?.value, className: "phone-parent" };
  
  requiredFields.forEach((field) => {
    if (!(field in contactDetails)) {
      contactDetails[field] = { value: "N/A", className: `${field.toLowerCase()}-parent` };
    }
  });

  const labelMap: Record<string, string> = {
    "Mobil": "Mobile",
    "Telefon": "Phone"
  };

  return (
  <Flex wrap direction="row" gap="size-100" justifyContent="space-evenly" width="100%">
  {Object.entries(contactDetails).map(([label, { value, className }]) => {
     const displayLabel = labelMap[label] || label
     return(<View key={label} flex="1" UNSAFE_className={styles[className]} padding={5}>
      {value ? (
        <Flex direction="column" gap="size-100">
          <View UNSAFE_className={styles.mobile}>{displayLabel}</View>
          <View UNSAFE_className={styles.div}>{value}</View>
        </Flex>
      ) : (
        <View UNSAFE_className={styles.mobile} UNSAFE_style={{ width: "100%"}}>
          {displayLabel}
        </View>
      )}
    </View>)
})}
</Flex>
  );
};

const getIconsDetailsUI = (contact: any): JSX.Element => {
  const contactDetails: Record<string, { value: string | undefined; className: string, icon?: JSX.Element }> = {};
  
  // Extract contacts from response
  const contactsArray: any[] = [];
  
  if (contact.CONTACTS) {
    if (Array.isArray(contact.CONTACTS)) {
      contactsArray.push(...contact.CONTACTS.map((item:any) => item.CONTACT));
    } else if (contact.CONTACTS.CONTACT) {
      if (Array.isArray(contact.CONTACTS.CONTACT)) {
        contactsArray.push(...contact.CONTACTS.CONTACT);
      } else {
        contactsArray.push(contact.CONTACTS.CONTACT);
      }
    }
  }

  contactsArray.forEach((c) => {
    const typeInfo = contactSettings[c.type];
    if (typeInfo) {
      contactDetails[typeInfo.name] = { value: c.content, className: typeInfo.className };
    }
  });
  contactDetails["Facebook"] = { 
    value: contactDetails["Facebook"]?.value, 
    className: "icon_facebook", // Fixed className (was "icon_twitter")
    icon: <FacebookIcon size={25} />  // Fixed icon (was TwitterIcon)
  };
  
  contactDetails["Twitter"] = { 
    value: contactDetails["Twitter"]?.value, 
    className: "icon_twitter", // Fixed className (was "icon_facebook")
    icon: <TwitterIcon size={25} /> 
  };
  
  contactDetails["LinkedIn"] = { 
    value: contactDetails["LinkedIn"]?.value, 
    className: "icon_linkedin",
    icon: <LinkdinIcon size={25} /> 
  };

  // Ensure required fields always appear
  requiredIcons.forEach((field) => {
    if (!(field in contactDetails)) {
      contactDetails[field] = { 
        value: undefined, // Changed from "N/A" to undefined for better link handling
        className: `icon_${field.toLowerCase()}`
      };
    }
  });

  // Generate UI elements with proper link handling
  return (
    <View UNSAFE_className={styles.iconsstates2}>
      {Object.entries(contactDetails)
        .filter(([label]) => ["Facebook", "Twitter", "LinkedIn"].includes(label))
        .map(([label, { value, className, icon }]) => (
          <View key={label} UNSAFE_className={className}>
            {value ? (
              <Link target='_blank' UNSAFE_className={styles[`${className}_active`]} href={value}>
                {icon}
              </Link>
            ) : (
              <span>{icon}</span>
            )}
          </View>
        ))}
    </View>
  );  
};

const handleEmployeeChange = (search: any) => {
  search = search.trim();

  if (search === "") {
    setEmployeesData(employees);
    return;
  }

  const filteredEmployees = employeesData.filter((x: any) =>
    x.name1.toLowerCase().includes(search.toLowerCase()) || 
    x.name3.toLowerCase().includes(search.toLowerCase())
  );

  setEmployeesData(filteredEmployees);
};

const addStatusColor = (type:string) => {
  let customStatusName = '';
  if(!contact.FOLDERS){
    return;
  }
  if(!Array.isArray(contact.FOLDERS)){
      customStatusName = contact.FOLDERS?.FOLDER?.customStateName;
  }else{
      const filteredFolder = contact.FOLDERS.find((item:any) => item.FOLDER.type === type);
      customStatusName = filteredFolder?.FOLDER?.customStateName;
  }
  if(customStatusName !=''){
    const statusObj = contactStatusList.find((obj: any) => obj.name === customStatusName);
    return statusObj?.color;
  }else{
    return ''
  }
};

  return (
      <Flex direction={'column'} UNSAFE_className={styles.detailViewParent}>
      <Flex justifyContent={'space-between'}>
      <View UNSAFE_className={styles['icon-contacts-parent']}>
                  <b className={styles['adam-novotn']}><span className={
                   +contactDataRow?.folderCount > 0 ?styles.contact_icon_orange:styles.contact_icon_grey
                  }><ContactIcon size={20} color={'#ff9800'} /></span> {contact.name3} {contact.name1}</b>
      </View>
      <View>
            <View UNSAFE_className={styles.iconsstates1}>
              <ActionButton isQuiet onPress={()=>handleEditClick(true)}>
                <EditIcon size={20} />
              </ActionButton>
              <ActionButton isQuiet>
                <TrashIcon size={20} />
              </ActionButton>
              <ActionButton isQuiet>
                <MoreIcon size={20} />
              </ActionButton>
            </View>
          </View>
      </Flex>
      <Flex justifyContent={'space-between'} direction={'row'}>
      <Flex direction={'row'} gap={'size-100'} UNSAFE_style={{paddingTop:10}}>
            {listOrganizationFolder && listOrganizationFolder.map(((folder:any)=>(
              <View key={folder.folderId} UNSAFE_className={styles.tag} UNSAFE_style={{backgroundColor:addStatusColor(folder.folderTypeId)}}>
                <View UNSAFE_className={styles.mobile}>
                  <a onClick={()=>handleFolderClick(folder.folderId)}>
                    {folder.folderTypeName}
                    </a>
                  </View>
              </View>
            )))}
          </Flex>
          {getIconsDetailsUI(contact)}
      </Flex>
      <View paddingTop={10} UNSAFE_className={styles['frame-group']}>{getContactDetailsUI(contact)}</View>
      <View paddingTop={10}>
      <Flex UNSAFE_className={styles['frame-container']} wrap>
              <View UNSAFE_className={styles['mobile-parent']}>
                <View UNSAFE_className={styles.mobile}>Last modification</View>
                <View UNSAFE_className={styles['alena-janik']}>{moment(contact.modified).format('MM/DD/YYYY')}</View>
              </View>
              <View UNSAFE_className={styles['phone-parent']}>
                <View UNSAFE_className={styles.mobile}>Modified by</View>
                <View UNSAFE_className={styles['alena-janik']}>{contact.modifiedByName}</View>
              </View>
              <View UNSAFE_className={styles['created-parent']}>
                <View UNSAFE_className={styles.mobile}>Created</View>
                <View UNSAFE_className={styles.div3}>{moment(contact.created).format('MM/DD/YYYY')}</View>
              </View>
            </Flex>
            </View>
            <Divider size='M' marginTop={5} />
            <Flex UNSAFE_className={styles['frame-div']}>
        {address && address.street &&
          <View UNSAFE_className={styles['address-parent']}>
            <b className={styles.address}>Address</b>
            <View UNSAFE_className={styles['value-wrapper']}>
                <View UNSAFE_className={styles.value}>
                  <p className={styles.bristol}>{address.street}</p>
                  <p className={styles.bristol}>{address.cityName}</p>
                  <p className={styles.bristol}>{address.zipName}</p>
                </View>
            </View>
          </View>
        }
      </Flex>
      <Flex UNSAFE_className={styles['frame-div']}>
          <Flex UNSAFE_className={styles['address-parent']}>
        {listOrganizationFolder && listOrganizationFolder.map(((folder:any)=>(
          <>
            <b key={folder.id} className={styles.address}>{folder.folderTypeName}</b>
            <View UNSAFE_className={styles['value-parent']}>
              <View UNSAFE_className={styles.active}>{folder.contactName}</View>
              <View UNSAFE_className={styles.title}>
                <View UNSAFE_className={styles['ellipse-parent']}>
                  <View UNSAFE_className={styles['frame-child']} />
                  <View UNSAFE_className={styles.active}>Active</View>
                </View>
              </View>
            </View>
            </>
        )))}
        </Flex>
      </Flex>
      {employeesData &&
      <Flex UNSAFE_className={styles['frame-parent1']}>
        <View UNSAFE_className={styles["employee-container"]}>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading level={3}>Employees</Heading>
        <Flex gap="size-100">
          <ActionButton isQuiet aria-label="Filter">
            <Filter/>
          </ActionButton>
          <ActionButton isQuiet aria-label="Add">
            <Plus />
          </ActionButton>
        </Flex>
      </Flex>

<Flex direction={'column'} width={'100%'} alignItems={'start'} justifyContent={'start'} 
UNSAFE_className={styles.activity_search_input} 
marginStart={'0px'} marginEnd={'0px'}>
          <Flex direction={'row'} height={'32px'} alignSelf={'stretch'} flexShrink={0} alignItems={'center'} 
          justifyContent={'end'} UNSAFE_className={styles.activity_search}>
            {/* <TextField value={filterTerm} width={'100%'} onChange={handleFiltering} aria-label="none" /> */}
            <TextField width={'100%'} aria-label="none" onChange={handleEmployeeChange} />
            <i className={`${styles.icon_activity_search} bi bi-search`}></i>
          </Flex>
        </Flex>
      <ListView 
        UNSAFE_className={styles["employee-list"]}
        selectionMode='none'
        onAction={handleEmployeeListClick}
      >
        {employeesData.map((employee:any) => (
          <Item key={employee.id} textValue={employee.name1}>
            <Flex direction={'column'}>
              <View UNSAFE_className={styles["employee-name"]}>{employee.name3} {employee.name1}</View>
              <View   UNSAFE_className={styles["employee-details"]}>
                {employee.positionInfo} {employee.departmentName && <ChevronRight size='S' UNSAFE_style={{color:'#909090'}}/>}
                {employee.positionName} 
                {employee.departmentName && <ChevronRight size='S' UNSAFE_style={{color:'#909090'}}/>}
                {employee.departmentName}
              </View>
            </Flex>
          </Item>
        ))}
      </ListView>
        </View>
      </Flex> 
}
      </Flex>
  );
};

export default ContactDetailView;