import { Flex, Link, View, Image, ProgressCircle } from '@adobe/react-spectrum';
import { truncateText } from '../../../../../utils/helperUtil';
import { useReefConfig } from '../../../../../hooks/UseReefConfig';
import { useState } from 'react';

interface IMessageAttachmentComponentProps {
  store: any;
  messageContent: any;
  styles: any;
}

const MessageAttachment: React.FC<IMessageAttachmentComponentProps> = ({ store, messageContent, styles }) => {
  const { baseUrl, loginInfo } = useReefConfig();
  const [hoveredAttachment, setHoveredAttachment] = useState<string | null>(null);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);

  // Function to check if file is an image based on extension
  const isImageFile = (filename: string) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
    const extension = filename.split('.').pop()?.toLowerCase();
    return extension && imageExtensions.includes(extension);
  };

  const renderAttachment = (item: any) => {
    const imageUrl = `${baseUrl}/asp-cgi/doc-download.cgi?instance=${loginInfo.instanceName}&session=${store.SessionId}&documentId=${item.id}&disposition=inline`;
    const isImage = isImageFile(item.name);

    return (
      <View position="relative" key={item.id}>
        <div
          onMouseEnter={() => {
            if (isImage) {
              setHoveredAttachment(item.id);
              setIsImageLoading(true);
            }
          }}
          onMouseLeave={() => setHoveredAttachment(null)}
        >
          <Link
            isQuiet
            onPress={(e: any) => {
              const windowReference = window.open('', '_blank', 'height=800,width=1200,scrollbars=yes,status=yes,menubar=no,toolbar=no');
              windowReference?.location.replace(imageUrl);
            }}
            UNSAFE_style={{ color: '#728289' }}
          >
            <i title={item.name} className={`bi bi-paperclip fs-8`}>
              {truncateText(item.name, 20)}
            </i>
          </Link>
        </div>

        {/* Image Preview on Hover */}
        {hoveredAttachment === item.id && isImage && (
          <View
            position="absolute"
            zIndex={100}
            backgroundColor="gray-50"
            borderWidth="thin"
            borderColor="gray-400"
            borderRadius="medium"
            padding="size-100"
            width="size-3000"
            bottom="100%"
            marginBottom="size-100"
            UNSAFE_style={{
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            }}
          >
            {isImageLoading && (
              <Flex alignItems="center" justifyContent="center" height="size-1000">
                <ProgressCircle aria-label="Loading image..." size="S" />
              </Flex>
            )}
            <Image src={imageUrl} alt={item.name} objectFit="contain" maxHeight="size-2000" onLoad={() => setIsImageLoading(false)} UNSAFE_style={{ display: isImageLoading ? 'none' : 'block' }} />
          </View>
        )}
      </View>
    );
  };

  return (
    messageContent.MESSAGE?.ATTACHMENT && (
      <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'6px'} wrap>
        {Array.isArray(messageContent.MESSAGE.ATTACHMENT) ? messageContent.MESSAGE.ATTACHMENT.map(renderAttachment) : renderAttachment(messageContent.MESSAGE?.ATTACHMENT)}
      </Flex>
    )
  );
};

export default MessageAttachment;
