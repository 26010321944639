import { useCallback, useRef, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { useSelector } from 'react-redux';
import { ArrowClockwise, ArrowLeft, ArrowRight, Plus, Trash } from 'react-bootstrap-icons';
import { AlertIconFill, ArrowForwardIcon, MoreIcon, ReplyAllIcon, ReplyIcon, SearchIcon, TrashIcon } from '../../Icons/IconsLib';
import FilterIcon from '@spectrum-icons/workflow/Filter';
import {
  Flex,
  Content,
  ProgressCircle,
  Button,
  View,
  Link,
  ListBox,
  IllustratedMessage,
  Heading,
  Item,
  ActionButton,
  DialogTrigger,
  Dialog,
  MenuTrigger,
  Menu,
  TextField,
  DatePicker,
  Text,
  Checkbox,
  Divider,
  Form,
  Footer,
  Provider,
} from '@adobe/react-spectrum';
import { ToastQueue } from '@react-spectrum/toast';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import { RootState } from '../../../../state/store';
import moment from 'moment';
import { useAsyncList } from 'react-stately';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { useTranslation } from 'react-i18next';
import { ListMessageRequest } from '../../../../services/soap/message/requests/ListMessageRequest';
import CardComponent from './component/CardComponent';
import CardComponentLarge from './component/CardComponentLarge';
import { SetSeenMessageRequest } from '../../../../services/soap/message/requests/SetSeenMessageRequest';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import { GetMessageRequest } from '../../../../services/soap/message/requests/GetMessageRequest';
import { DeleteMessageRequest } from '../../../../services/soap/message/requests/DeleteMessageRequest';
import { GetListMessageRequest } from '../../../../services/soap/message/requests/GetListMessageRequest';
import MessageAttachment from './component/MessageAttachmentComponent';
import NewMessage from './component/NewMessageComponent';
import './message_list_component.css';
import styles from './message_list_component.module.css';

interface MessageListProps {
  readonly selectedItem?: FPAData;
}
export interface Character {
  id: string;
}

function MessageList({ selectedItem }: MessageListProps) {
  const { t } = useTranslation();
  const componentRef = useRef<HTMLDivElement>(null);
  const { store, messageService, mainService } = useDependency();
  const selectedApp = useSelector((state: RootState) => state.userWebLayout.selectedAppId);
  const { isMobile } = useViewInfo();
  const [rowsData, setRowsData] = useState<any>([]);
  const [messageContent, setMessageContent] = useState<any>('');
  //const [searchedValue, setSearchedValue] = useState<string>('');
  const searchedText = useRef<string>('');
  const [selectedKeys, setSelectedKeys] = useState<Set<any>>();
  const [selectedDataRow, setSelectedDataRow] = useState<any>({});
  const [navigationIndex, setNavigationIndex] = useState<number>(0);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [showLoaderDetail, setShowLoaderDetail] = useState(true);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [stopLoadData, setStopLoadData] = useState<boolean>(false);
  const [showNewMessageDialog, setShowNewMessageDialog] = useState<boolean>(false);
  const [totalMessageListRec, setTotalMessageListRec] = useState<number>(0);
  const filterParam = useRef({ createdFrom: '', createdTo: '', sender: '', subject: '', newOnly: false, starred: false });
  const offSetLimit = useRef<number>(0);
  const PAGE_SIZE = 50;
  let iconStyle = { paddingTop: '5px', paddingLeft: '5px', color: '#B0BEC5', WebkitTextStroke: '.5px' };

  // useEffect(() => {
  //   (async () => {
  //     setShowLoaderDetail(true);
  //     setSearchedValue('');
  //   })();
  // }, [selectedApp]);

  const getMessageListCount = async (filterText: any) => {
    let messageRes = await messageService.listMessage(
      new ListMessageRequest(store.Server, store.SessionId, {
        dateTo: filterParam.current.createdTo,
        dateFrom: filterParam.current.createdFrom,
        contains: filterText,
        senderPlain: filterParam.current.sender,
        newOnly: filterParam.current.newOnly,
        orderBy: 'created',
        fromNode: true,
        treeHandle: selectedItem?.id,
        showInbox: false,
        tree: 0,
        showDeleted: false,
        showSent: false,
        showDrafts: false,
        showAll: false,
        showAllMy: false,
        starred: filterParam.current.starred,
        subjectContains: filterParam.current.subject,
        countOnly: true,
      })
    );
    //console.log('messageRes Count', messageRes);
    if (messageRes?.result == 'OK') {
      setTotalMessageListRec(messageRes.count != 0 ? messageRes.count : 0);
      return messageRes.count != 0 ? messageRes.count : 0;
    }
  };

  const updateFilterParam = () => {
    if (filterParam.current.createdTo) {
      filterParam.current.createdTo = moment(filterParam.current.createdTo).add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00';
    }
    if (filterParam.current.createdFrom) {
      filterParam.current.createdFrom = moment(filterParam.current.createdFrom).format('YYYY-MM-DD') + 'T00:00:00';
    }
  };

  const loadMessageList = async (filterText: any) => {
    try {
      if (stopLoadData) {
        return rowsData;
      }
      updateFilterParam();
      const requestParams = {
        offset: offSetLimit.current,
        limit: PAGE_SIZE,
        dateTo: filterParam.current.createdTo,
        dateFrom: filterParam.current.createdFrom,
        contains: filterText,
        senderPlain: filterParam.current.sender,
        newOnly: filterParam.current.newOnly,
        order: 'down',
        orderBy: 'created',
        fromNode: true,
        treeHandle: selectedItem?.id,
        showInbox: false,
        tree: 0,
        showDeleted: false,
        showSent: false,
        showDrafts: false,
        showAll: false,
        showAllMy: false,
        starred: filterParam.current.starred,
        subjectContains: filterParam.current.subject,
      };

      const messageRes: any = await messageService.listMessage(new ListMessageRequest(store.Server, store.SessionId, requestParams));
      console.log('messageRes.listId', messageRes);

      const handleMessageResponse = async (messageListRes: any) => {
        if (messageListRes?.result == 'OK' && messageListRes?.count > 0) {
          //console.log('messageRes', messageListRes);
          if (messageListRes.count < PAGE_SIZE) {
            setTotalMessageListRec(messageListRes.count);
          }
          const listMessages: any = messageListRes?.MESSAGE;
          setRowsData((prev: any) => [...prev, ...listMessages]);
          return listMessages;
        } else {
          setRowsData([]);
          return [];
        }
      };

      if (filterText !== '' && messageRes?.result == 'OK') {
        const messageListRes = await messageService.getListMessage(new GetListMessageRequest(store.Server, store.SessionId, messageRes.listId));
        return handleMessageResponse(messageListRes);
      }

      return handleMessageResponse(messageRes);
    } finally {
      setShowLoader(false);
      setShowLoaderDetail(false);
    }
  };

  let listMessageList = useAsyncList<Character>({
    async load({ cursor, filterText }) {
      //console.log('filterText', filterText);
      if (selectedApp === '') {
        setShowLoader(false);
        setShowLoaderDetail(false);
        return { items: rowsData, cursor: undefined };
      }
      if (cursor && Number(cursor) * PAGE_SIZE >= totalMessageListRec) {
        setShowLoader(false);
        setShowLoaderDetail(false);
        return { items: rowsData, cursor: undefined };
      }
      let totalRecords = await getMessageListCount(filterText);
      totalRecords = totalRecords ? Number(totalRecords) : 0;
      //console.log('totalRecords', totalRecords);
      let currentPage = cursor ? parseInt(cursor) : 0;
      //console.log('currentPage', currentPage);
      let offSet = PAGE_SIZE * currentPage;
      //console.log('offset', offSet);
      offSetLimit.current = offSet;
      let listMessages = await loadMessageList(filterText);
      //console.log('messageList', messageList);
      if (currentPage == 0) {
        setSelectedKeys(new Set([listMessages[0].id]));
        setSelectedDataRow(listMessages[0]);
        //SET SELECTED ROW EMAIL CONTENT
        let messageContent: any = await messageService.getMessage(new GetMessageRequest(store.Server, store.SessionId, listMessages[0].id));
        setMessageContent(messageContent);
      }
      return {
        items: listMessages,
        cursor: String(offSet >= totalRecords ? undefined : currentPage + 1),
      };
    },
  });

  const resetMessageListState = (filterText: string) => {
    setStopLoadData(false);
    setShowLoader(true);
    setShowLoaderDetail(true);
    setSelectedKeys(new Set());
    setSelectedDataRow(null);
    setMessageContent('');
    setNavigationIndex(0);
    setTotalMessageListRec(0);
    setRowsData([]);
    offSetLimit.current = 0;
    listMessageList.setFilterText(filterText);
  };

  const searchMessageList = useCallback(async () => {
    filterParam.current = { createdFrom: '', createdTo: '', sender: '', subject: '', newOnly: false, starred: false };
    resetMessageListState(searchedText.current);
  }, []);

  const reloadMessageList = useCallback(() => {
    filterParam.current = { createdFrom: '', createdTo: '', sender: '', subject: '', newOnly: false, starred: false };
    resetMessageListState('');
  }, []);

  const filterMessageList = useCallback(() => {
    resetMessageListState('');
  }, []);

  const handleListRowClick = async (rowId: any): Promise<void> => {
    const currentKey = rowId?.currentKey;
    if (!currentKey || selectedKeys?.has(currentKey)) return;

    setShowLoaderDetail(true);
    setSelectedKeys(rowId);

    const recIndex = rowsData.findIndex((item: any) => item.id === currentKey);
    setNavigationIndex(recIndex);

    const selectedRow = rowsData.find((item: any) => item.id === currentKey);
    if (!selectedRow) {
      setSelectedDataRow(null);
      setSelectedKeys(new Set());
      setShowLoaderDetail(false);
      return;
    }

    setSelectedDataRow(selectedRow);

    const messageContent = await getEmailContent(selectedRow);
    setMessageContent(messageContent);
    setShowLoaderDetail(false);
  };

  const handleListRowLargeClick = async (rowId: any): Promise<void> => {
    const currentKey = rowId?.currentKey || selectedDataRow?.id;
    if (!currentKey) return;

    setShowLoaderDetail(true);
    setSelectedKeys(rowId ?? selectedKeys);

    const recIndex = rowsData.findIndex((item: any) => item.id === currentKey);
    setNavigationIndex(recIndex);

    const selectedRow = rowsData.find((item: any) => item.id === currentKey);
    if (!selectedRow) {
      setSelectedDataRow(null);
      setSelectedKeys(new Set());
      setShowLoaderDetail(false);
      return;
    }

    setSelectedDataRow(selectedRow);

    const messageContent = await getEmailContent(selectedRow);
    setMessageContent(messageContent);
    setShowLoaderDetail(false);
    setShowDetail(true);
  };

  const Loader = () => (
    <Flex width="100%" justifyContent="center" marginTop={10}>
      <ProgressCircle aria-label="Loading…" isIndeterminate />
    </Flex>
  );

  const NoResultsMessage = () => (
    <Flex direction="row" alignContent="center" justifyContent="center" marginTop={'30px'}>
      <IllustratedMessage>
        <NotFound />
        <Heading>{t('no_results', { ns: 'layout_components' })}</Heading>
      </IllustratedMessage>
    </Flex>
  );

  const getSenderName = (fullName: string) => {
    const match = fullName.match(/(.*)<.*>/);
    return match ? match[1].trim() : fullName;
  };

  const getSenderEmail = (email: string) => {
    const match = /<(.+?)>/.exec(email);
    return match ? match[1] : email;
  };

  const getEmailContent = async (selectedRow: any) => {
    let messageContent: any = await messageService.getMessage(new GetMessageRequest(store.Server, store.SessionId, selectedRow.id));
    if (selectedRow.seen == '0') {
      await messageService.setSeenMessage(new SetSeenMessageRequest(store.Server, store.SessionId, selectedRow.id));
    }
    //console.log('messageContent', messageContent.MESSAGE.ATTACHMENT);
    return messageContent;
  };

  const nextNavigation = async () => {
    setShowLoaderDetail(true);
    setSelectedKeys(new Set([rowsData[navigationIndex + 1].id]));
    //console.log('rowsData', rowsData);
    let selectedRow = rowsData.find((item: any) => item.id == rowsData[navigationIndex + 1].id);
    //console.log('selectedRow', selectedRow);
    if (!selectedRow) {
      return;
    }
    setSelectedDataRow(selectedRow);
    //SET SELECTED ROW EMAIL CONTENT
    let messageContent = await getEmailContent(selectedRow);
    setMessageContent(messageContent);
    setShowLoaderDetail(false);
    setNavigationIndex(prev => prev + 1);
  };

  const previousNavigation = async () => {
    setShowLoaderDetail(true);
    setSelectedKeys(new Set([rowsData[navigationIndex - 1].id]));

    //console.log('rowsData', rowsData);
    let selectedRow = rowsData.find((item: any) => item.id == rowsData[navigationIndex - 1].id);
    //console.log('selectedRow', selectedRow);
    if (!selectedRow) {
      return;
    }
    setSelectedDataRow(selectedRow);
    //SET SELECTED ROW EMAIL CONTENT
    let messageContent = await getEmailContent(selectedRow);
    setMessageContent(messageContent);
    setShowLoaderDetail(false);
    setNavigationIndex(prev => prev - 1);
  };

  const deleteMessage = async (messageId: string) => {
    // Call the API to delete the message
    // realDelete: false means move to trash instead of permanent deletion
    let delMessageRes = await messageService.deleteMessage(
      new DeleteMessageRequest(store.Server, store.SessionId, {
        realDelete: false,
        MESSAGE: {
          id: messageId,
          parent: messageId,
        },
      })
    );

    if (delMessageRes?.result == 'OK') {
      // Remove the message from the async list
      listMessageList.remove(messageId);

      // Find the index of the deleted message
      let nextIndex = rowsData.findIndex((item: any) => item.id == messageId);
      // Get the next message in the list (if any) to show after deletion
      let nextMessageObj = rowsData.length >= nextIndex + 1 ? rowsData[nextIndex + 1] : null;

      // Update the rows data by filtering out the deleted message
      setRowsData((prev: any) => prev.filter((item: any) => item.id !== messageId));

      // Update the selected message to the next message in the list
      setSelectedKeys(new Set([nextMessageObj.id]));
      setSelectedDataRow(nextMessageObj);

      // Fetch and set the content of the next message
      let messageContent = await getEmailContent(nextMessageObj);
      setMessageContent(messageContent);

      // Show success notification
      ToastQueue.info(t('message_deleted_successfully', { ns: 'layout_components' }), { timeout: 50 });
    } else {
      // Show error notification if deletion failed
      ToastQueue.negative(t('error_deleting_message', { ns: 'layout_components' }), { timeout: 50 });
    }
  };

  const MessageDialog = ({ close }: { close: () => void }) => (
    <Dialog size="S">
      <Flex UNSAFE_className={styles.delete_pop_up_parent}>
        <Content UNSAFE_className={styles.delete_pop_up_header}>{t('remove_message', { ns: 'layout_components' })}</Content>
        <View UNSAFE_className={styles.delete_pop_up_line}></View>
        <View UNSAFE_className={styles.delete_pop_up_warning_text}>{t('are_you_sure_you_want_to_remove_message', { ns: 'layout_components' })}</View>
        <View UNSAFE_className={styles.buttons_parent}>
          <View UNSAFE_className={styles.button_cancel}>
            <Link
              UNSAFE_className={styles.delete_pop_up_cancel_text}
              isQuiet
              onPress={() => {
                close();
              }}
            >
              {t('no_keep_it', { ns: 'layout_components' })}
            </Link>
          </View>
          <View UNSAFE_className={styles.button_confirm}>
            <div className={styles.trash_icon}>
              <TrashIcon size={32} />
            </div>
            <Link
              UNSAFE_className={styles.delete_pop_up_confirm_text}
              isQuiet
              onPress={() => {
                deleteMessage(selectedDataRow.id);
                close();
              }}
            >
              {t('yes_remove', { ns: 'layout_components' })}
            </Link>
          </View>
        </View>
        <View UNSAFE_className={styles.ellipse_div}>
          <View UNSAFE_className={styles.delete_alert_icon}>
            <AlertIconFill size={72} />
          </View>
        </View>
      </Flex>
    </Dialog>
  );

  const RemoveMessageButton = () => (
    <DialogTrigger isDismissable type="modal">
      <ActionButton UNSAFE_className={styles.btn_delete}>
        <Trash className={styles.icon_trash} size={24} />
      </ActionButton>
      {close => (
        <MessageDialog
          close={() => {
            close();
          }}
        />
      )}
    </DialogTrigger>
  );

  const SearchDialog = ({ close }: { close: () => void }) => (
    <Dialog size="S" UNSAFE_className={styles.dialog_parent}>
      <Heading>
        <Flex alignItems="center" gap="size-100">
          <Text>{t('search', { ns: 'layout_components' })}</Text>
        </Flex>
      </Heading>
      <Divider />
      <Content>
        <Form validationBehavior="native">
          <TextField
            autoFocus
            isRequired
            label={t('search', { ns: 'layout_components' })}
            maxWidth="100%"
            onChange={e => {
              searchedText.current = e;
            }}
            validationState="invalid"
          />
        </Form>
      </Content>
      <Footer>
        <Flex direction={'row'} width={'100%'} alignContent={'center'} justifyContent={'center'} alignItems={'center'} gap={'size-200'}>
          <Flex direction={'row'} width={'120px'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.search_button_cancel}>
            <Link
              UNSAFE_className={styles.search_pop_up_cancel_text}
              isQuiet
              onPress={() => {
                close();
              }}
            >
              {t('cancel', { ns: 'layout_components' })}
            </Link>
          </Flex>
          <Flex direction={'row'} width={'120px'} height={'40px'} alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.search_button_confirm}>
            <Link
              UNSAFE_className={styles.search_pop_up_confirm_text}
              isQuiet
              onPress={() => {
                if (searchedText.current == '') {
                  return;
                }
                searchMessageList();
                searchedText.current = '';
                close();
              }}
            >
              {t('search', { ns: 'layout_components' })}
            </Link>
          </Flex>
        </Flex>
      </Footer>
    </Dialog>
  );

  const SearchButton = () => (
    <DialogTrigger>
      <ActionButton UNSAFE_className={styles.no_border}>
        <SearchIcon size={24} />
      </ActionButton>
      {close => (
        <SearchDialog
          close={() => {
            close();
          }}
        />
      )}
    </DialogTrigger>
  );

  const FilterDialog = ({ close }: { close: () => void }) => (
    <Dialog size="M" UNSAFE_className={styles.dialog_parent}>
      <Heading>
        <Flex alignItems="center" gap="size-100">
          <Text>{t('Filter', { ns: 'layout_components' })}</Text>
        </Flex>
      </Heading>
      <Divider />
      <Content>
        <Form>
          <Provider locale="en-US" UNSAFE_style={{ width: '100%' }}>
            <DatePicker width={'100%'} autoFocus id="dtCreatedFrom" label="Created from" onChange={e => (filterParam.current.createdFrom = e?.toString() ?? '')} />
          </Provider>
          <Provider locale="en-US">
            <DatePicker width={'100%'} label="Created to" onChange={e => (filterParam.current.createdTo = e?.toString() ?? '')} />
          </Provider>
          <TextField label="Sender" onChange={e => (filterParam.current.sender = e)} />
          <TextField label="Subject" onChange={e => (filterParam.current.subject = e)} />
          <Checkbox onChange={e => (filterParam.current.newOnly = e)}>Only new</Checkbox>
          <Checkbox onChange={e => (filterParam.current.starred = e)}>Only starred</Checkbox>
        </Form>
      </Content>
      <Footer>
        <Flex direction={'row'} width={'100%'} alignContent={'center'} justifyContent={'center'} alignItems={'center'} gap={'size-200'}>
          <Flex direction={'row'} width={'120px'} height={'40px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.filter_button_cancel}>
            <Link
              UNSAFE_className={styles.filter_pop_up_cancel_text}
              isQuiet
              onPress={() => {
                close();
              }}
            >
              {t('cancel', { ns: 'layout_components' })}
            </Link>
          </Flex>
          <Flex direction={'row'} width={'120px'} height={'40px'} alignItems={'center'} justifyContent={'center'} UNSAFE_className={styles.filter_button_confirm}>
            <Link
              UNSAFE_className={styles.filter_pop_up_confirm_text}
              isQuiet
              onPress={() => {
                filterMessageList();
                close();
              }}
            >
              {t('search', { ns: 'layout_components' })}
            </Link>
          </Flex>
        </Flex>
      </Footer>
    </Dialog>
  );

  const FilterButton = () => (
    <DialogTrigger type="modal">
      <ActionButton UNSAFE_className={styles.no_border}>
        <FilterIcon UNSAFE_style={{ fill: '#b0bec5' }} />
      </ActionButton>
      {close => (
        <FilterDialog
          close={() => {
            close();
          }}
        />
      )}
    </DialogTrigger>
  );

  const menuAction = (key: string) => {
    if (key === 'delete') {
      deleteMessage(selectedDataRow.id);
    }
  };

  const renderMessageList = () => {
    return showLoaderDetail ? (
      <View position={'relative'} width={'100%'} height={'400px'} overflow={'hidden'} UNSAFE_className={styles.menu_messages_large_parent}>
        <Loader />
      </View>
    ) : (
      <View position={'relative'} width={'100%'} height={'100vh'} overflow={'hidden'} UNSAFE_className={styles.menu_messages_large_parent}>
        <View position={'absolute'} width={'100%'} height={'64px'} overflow={'hidden'}>
          <Flex direction={'row'} position={'absolute'} top={24} height={'32px'} alignItems={'center'} justifyContent={'center'} marginStart={12} UNSAFE_className={styles.btn_new_email}>
            {/* {showNewMessageDialog && <NewMessage styles={styles} store={store} messageService={messageService} dialogVisibility={showNewMessageDialog} />} */}
            <Button
              variant="primary"
              UNSAFE_style={{ background: 'transparent', border: 'none', width: '100%', fontSize: '14px' }}
              onPress={() => {
                setShowNewMessageDialog(true);
              }}
            >
              <>
                <Plus size={18} className={styles.btn_new_email_text} />
                <span className={styles.btn_new_email_text}>{t('new_email', { ns: 'layout_components' })}</span>
              </>
            </Button>
          </Flex>

          <Flex position={'absolute'} direction={'row'} alignItems={'center'} justifyContent={'end'} gap={18} right={10} top={25}>
            <ArrowClockwise className={styles.message_list_header_icon} onClick={reloadMessageList} />
            {/* <SearchIcon size={24} /> */}
            <SearchButton />
            {/* <FilterIcon UNSAFE_className={styles.message_list_header_icon} /> */}
            <FilterButton />
            <MoreIcon size={24} />
          </Flex>
        </View>
        <View
          position={'absolute'}
          top={'65px'}
          left={'0px'}
          width={'100%'}
          height={'100vh'}
          overflow={'hidden'}
          UNSAFE_style={{ fontSize: '12px', color: '#36474f', borderRadius: '16px', backgroundColor: '#fff' }}
        >
          <Flex direction={'column'} position={'absolute'} top={'0px'} left={'0px'} width={'100%'} alignItems={'start'} justifyContent={'start'}>
            <ListBox
              autoFocus
              id="listMessageCardsMobile"
              selectionMode="single"
              onSelectionChange={handleListRowLargeClick}
              defaultSelectedKeys={selectedKeys}
              selectedKeys={selectedKeys}
              aria-label="Message Cards"
              items={listMessageList.items}
              isLoading={listMessageList.isLoading}
              onLoadMore={totalMessageListRec > rowsData.length ? listMessageList.loadMore : undefined}
              width={'100%'}
              UNSAFE_style={{ padding: '0 0 0 5px' }}
            >
              {item => <Item key={item.id}>{isMobile ? <CardComponent item={item} /> : <CardComponentLarge item={item} />}</Item>}
            </ListBox>
          </Flex>
        </View>
      </View>
    );
  };

  const renderMessageListDetailLarge = () => {
    return (
      <View width={'100%'} position={'relative'} height={'100vh'} overflow={'hidden'} UNSAFE_className={styles.message_list_detail_wrapper_large}>
        <Flex
          direction={'column'}
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          height={'98%'}
          alignItems={'start'}
          justifyContent={'start'}
          UNSAFE_className={styles.message_list_detail_parent}
        >
          <Flex direction={'column'} height={'98%'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'start'}>
            <View position={'relative'} top={0} right={0} width={'100%'} height={'64px'} overflow={'hidden'} UNSAFE_className={styles.email_content_header_parent_large}>
              <Flex direction={'row'} position={'absolute'} top={'24px'} left={'10px'} justifyContent={'start'} gap={'18px'} alignItems={'center'}>
                <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'12px'}>
                  <View marginStart={'5px'} marginEnd={'3px'}>
                    <ArrowLeft
                      size={24}
                      className={styles.message_list_header_icon}
                      onClick={() => {
                        setShowDetail(false);
                      }}
                    />
                  </View>
                  <Flex direction={'row'} width={'34px'} height={'34px'} flexShrink={0} justifyContent={'center'} UNSAFE_className={styles.action_button}>
                    <ReplyIcon size={24} />
                  </Flex>
                  <Flex direction={'row'} width={'34px'} height={'34px'} flexShrink={0} justifyContent={'center'} UNSAFE_className={styles.action_button}>
                    <ReplyAllIcon size={24} />
                  </Flex>
                  <Flex direction={'row'} width={'34px'} height={'34px'} flexShrink={0} justifyContent={'center'} UNSAFE_className={styles.action_button}>
                    <ArrowForwardIcon size={24} />
                  </Flex>
                </Flex>
                <View position={'relative'} width={'1px'} height={'24px'} UNSAFE_className={styles.message_list_detail_header_divider}></View>
                <Flex direction={'row'} height={'32px'} alignItems={'center'} justifyContent={'center'} width={'100px'} UNSAFE_className={styles.btn_comment}>
                  <button className={styles.btn_comment_text} style={{ background: 'transparent', border: 'none', width: '100%', fontSize: '14px' }}>
                    {t('comment', { ns: 'layout_components' })}
                  </button>
                </Flex>
              </Flex>
              <Flex direction={'row'} position={'absolute'} top={'27px'} right={'20px'} alignItems={'center'} justifyContent={'start'} gap={'10px'}>
                <RemoveMessageButton />
                <MoreIcon size={24} />
              </Flex>
            </View>
            <Flex direction={'column'} width={'100%'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.email_content_header_parent_large}>
              <Flex
                direction={'row'}
                alignSelf={'stretch'}
                alignItems={'start'}
                justifyContent={'start'}
                gap={'18px'}
                UNSAFE_style={{ borderBottom: '1px solid #eceff1', paddingLeft: '13px', paddingBottom: '5px' }}
              >
                <View UNSAFE_className={styles.user_img}>
                  <img className={styles.avatar_desktop_light} alt="" src="../../../../../../assets/images/avatar--desktop--light@2x.png" />
                </View>
                <Flex direction={'column'} width={'100%'} alignItems={'start'} justifyContent={'start'} gap={'5px'}>
                  <Flex direction={'row'} alignSelf={'stretch'} alignItems={'end'} justifyContent={'space-between'}>
                    <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} gap={'8px'}>
                      <View UNSAFE_className={styles.user_full_name}>{getSenderName(selectedDataRow.sender)}</View>
                      <View width={'1px'} position={'relative'} height={'12px'} UNSAFE_className={styles.user_detail_divider}></View>
                      <View UNSAFE_className={styles.user_email}>{getSenderEmail(selectedDataRow.mailFrom)}</View>
                    </Flex>
                    <View position={'relative'} right={'25px'} UNSAFE_className={styles.div_date}>
                      1-{moment(selectedDataRow.date).format('DD.MM.YYYY HH:mm')}
                    </View>
                  </Flex>
                  <Flex direction={'column'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} gap={'8px'} UNSAFE_className={styles.subject_parent}>
                    <View position={'relative'} alignSelf={'stretch'} UNSAFE_className={styles.email_subject}>
                      {selectedDataRow.subject}
                    </View>
                    <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'6px'}>
                      <View UNSAFE_className={styles.address}>{messageContent.MESSAGE?.folderName}</View>
                      {messageContent.MESSAGE?.projectName && (
                        <>
                          <View UNSAFE_className={styles.icon_chevron_right}> {'>'} </View>
                          <View UNSAFE_className={styles.address}>{messageContent.MESSAGE?.projectName}</View>
                        </>
                      )}
                      {messageContent.MESSAGE?.activityName && (
                        <>
                          <View UNSAFE_className={styles.icon_chevron_right}> {'>'} </View>
                          <View UNSAFE_className={styles.address}>{messageContent.MESSAGE?.activityName}</View>
                        </>
                      )}
                    </Flex>
                    <MessageAttachment store={store} styles={styles} messageContent={messageContent} />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <View width={'93%'} position={'relative'} UNSAFE_className={styles.email_content} height={'75%'}>
              <div dangerouslySetInnerHTML={{ __html: messageContent.MESSAGE?.PREVIEW?.content }} />
            </View>
          </Flex>
        </Flex>
        <Flex direction={'column'} position={'absolute'} bottom={'16px'} left={'calc(50% - 128.5px)'} alignItems={'center'} justifyContent={'end'}>
          <Flex direction={'row'} width={'258px'} alignItems={'center'} justifyContent={'center'} gap={'32px'}>
            {navigationIndex > 0 && (
              <Flex direction={'row'} height={'32px'} alignItems={'center'} justifyContent={'center'} gap={'8px'} UNSAFE_className={styles.btn_previous}>
                <Button variant="primary" onPress={previousNavigation} UNSAFE_style={{ background: 'transparent', border: 'none', width: '100%' }}>
                  <>
                    <ArrowLeft className={styles.icon_navigate} />
                    &nbsp;&nbsp;&nbsp;
                    <View UNSAFE_className={styles.btn_text}>{t('previous', { ns: 'layout_components' })}</View>
                  </>
                </Button>
              </Flex>
            )}
            {navigationIndex < rowsData.length - 1 && (
              <Flex direction={'row'} width={'113px'} height={'32px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} gap={'8px'} UNSAFE_className={styles.btn_next}>
                <Button variant="primary" onPress={nextNavigation} UNSAFE_style={{ background: 'transparent', border: 'none', width: '100%' }}>
                  <>
                    <View UNSAFE_className={styles.btn_text}>{t('next', { ns: 'layout_components' })}</View>
                    &nbsp;&nbsp;&nbsp;
                    <ArrowRight className={styles.icon_navigate} />
                  </>
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
      </View>
    );
  };

  const renderMessageListDetailMobile = () => {
    return (
      <View width={'100%'} position={'relative'} height={'100vh'} overflow={'hidden'} UNSAFE_className={styles.message_list_detail_wrapper_large}>
        <Flex
          direction={'column'}
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          height={'98%'}
          alignItems={'start'}
          justifyContent={'start'}
          UNSAFE_className={styles.message_list_detail_parent}
        >
          <Flex direction={'column'} height={'98%'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'start'}>
            <View position={'relative'} top={0} right={0} width={'100%'} height={'64px'} overflow={'hidden'} UNSAFE_className={styles.email_content_header_parent_large}>
              <Flex direction={'row'} position={'absolute'} top={'25px'} left={'10px'} justifyContent={'start'} gap={'18px'} alignItems={'center'}>
                <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'12px'}>
                  <View marginStart={'5px'} marginEnd={'3px'}>
                    <ArrowLeft
                      size={24}
                      className={styles.message_list_header_icon}
                      onClick={() => {
                        setShowDetail(false);
                      }}
                    />
                  </View>
                  <Flex direction={'row'} width={'34px'} height={'34px'} flexShrink={0} justifyContent={'center'} UNSAFE_className={styles.action_button}>
                    <ReplyIcon size={24} />
                  </Flex>
                  <Flex direction={'row'} width={'34px'} height={'34px'} flexShrink={0} justifyContent={'center'} UNSAFE_className={styles.action_button}>
                    <ReplyAllIcon size={24} />
                  </Flex>
                  <Flex direction={'row'} width={'34px'} height={'34px'} flexShrink={0} justifyContent={'center'} UNSAFE_className={styles.action_button}>
                    <ArrowForwardIcon size={24} />
                  </Flex>
                </Flex>
                <View position={'relative'} width={'1px'} height={'24px'} UNSAFE_className={styles.message_list_detail_header_divider}></View>
                <Flex direction={'row'} height={'32px'} alignItems={'center'} justifyContent={'center'} width={'100px'} UNSAFE_className={styles.btn_comment}>
                  <button className={styles.btn_comment_text} style={{ background: 'transparent', border: 'none', width: '100%', fontSize: '14px' }}>
                    {t('comment', { ns: 'layout_components' })}
                  </button>
                </Flex>
              </Flex>
              <Flex direction={'row'} position={'absolute'} top={'32px'} right={'2px'} alignItems={'center'} justifyContent={'start'}>
                {/* <RemoveMessageButton /> */}
                <MenuTrigger>
                  <Link>
                    <span aria-label="Open context menu" role="button">
                      <i className="bi bi-three-dots fs-7"></i>
                    </span>
                  </Link>
                  <Menu width={'100%'} onAction={(key: any) => menuAction(key)}>
                    <Item key="delete" textValue="delete">
                      <i className="bi bi-trash" style={iconStyle}></i>&nbsp;&nbsp;
                      <span style={{ color: '#36474F', paddingLeft: '5px', paddingTop: '4px' }}>{t('remove', { ns: 'layout_components' })}</span>
                    </Item>
                  </Menu>
                </MenuTrigger>
              </Flex>
            </View>
            <Flex direction={'column'} width={'100%'} alignItems={'start'} justifyContent={'start'} UNSAFE_className={styles.email_content_header_parent_large}>
              <Flex
                direction={'row'}
                alignSelf={'stretch'}
                alignItems={'start'}
                justifyContent={'start'}
                gap={'18px'}
                UNSAFE_style={{ borderBottom: '1px solid #eceff1', paddingLeft: '13px', paddingBottom: '15px' }}
              >
                <View UNSAFE_className={styles.user_img}>
                  <img className={styles.avatar_desktop_light} alt="" src="../../../../../../assets/images/avatar--desktop--light@2x.png" />
                </View>
                <Flex direction={'column'} width={'100%'} alignItems={'start'} justifyContent={'start'} gap={'5px'}>
                  <Flex direction={'row'} alignSelf={'stretch'} alignItems={'end'} justifyContent={'space-between'}>
                    <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} gap={'8px'}>
                      <View UNSAFE_className={styles.user_full_name}>{getSenderName(selectedDataRow.sender)}</View>
                      <View width={'1px'} position={'relative'} height={'12px'} UNSAFE_className={styles.user_detail_divider}></View>
                      <View UNSAFE_className={styles.user_email}>{getSenderEmail(selectedDataRow.mailFrom)}</View>
                    </Flex>
                  </Flex>
                  <Flex direction={'column'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} gap={'8px'} UNSAFE_className={styles.subject_parent}>
                    <View position={'relative'} alignSelf={'stretch'} UNSAFE_className={styles.email_subject}>
                      {selectedDataRow.subject}
                    </View>
                    <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'6px'}>
                      <View UNSAFE_className={styles.address}>{messageContent.MESSAGE?.folderName}</View>
                      {messageContent.MESSAGE?.projectName && (
                        <>
                          <View UNSAFE_className={styles.icon_chevron_right}> {'>'} </View>
                          <View UNSAFE_className={styles.address}>{messageContent.MESSAGE?.projectName}</View>
                        </>
                      )}
                      {messageContent.MESSAGE?.activityName && (
                        <>
                          <View UNSAFE_className={styles.icon_chevron_right}> {'>'} </View>
                          <View UNSAFE_className={styles.address}>{messageContent.MESSAGE?.activityName}</View>
                        </>
                      )}
                    </Flex>

                    <View position={'relative'} UNSAFE_className={styles.div_date}>
                      2-{moment(selectedDataRow.date).format('DD.MM.YYYY HH:mm')}
                    </View>
                    <MessageAttachment store={store} styles={styles} messageContent={messageContent} />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <View width={'93%'} position={'relative'} UNSAFE_className={styles.email_content} height={'55%'}>
              <div dangerouslySetInnerHTML={{ __html: messageContent.MESSAGE?.PREVIEW?.content }} />
            </View>
          </Flex>
        </Flex>
        <Flex direction={'column'} position={'absolute'} bottom={'55px'} left={'calc(50% - 128.5px)'} alignItems={'center'} justifyContent={'end'}>
          <Flex direction={'row'} width={'258px'} alignItems={'center'} justifyContent={'center'} gap={'32px'}>
            {navigationIndex > 0 && (
              <Flex direction={'row'} height={'32px'} alignItems={'center'} justifyContent={'center'} gap={'8px'} UNSAFE_className={styles.btn_previous}>
                <Button variant="primary" onPress={previousNavigation} UNSAFE_style={{ background: 'transparent', border: 'none', width: '100%' }}>
                  <>
                    <ArrowLeft className={styles.icon_navigate} />
                    &nbsp;&nbsp;&nbsp;
                    <View UNSAFE_className={styles.btn_text}>{t('previous', { ns: 'layout_components' })}</View>
                  </>
                </Button>
              </Flex>
            )}
            {navigationIndex < rowsData.length - 1 && (
              <Flex direction={'row'} width={'113px'} height={'32px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} gap={'8px'} UNSAFE_className={styles.btn_next}>
                <Button variant="primary" onPress={nextNavigation} UNSAFE_style={{ background: 'transparent', border: 'none', width: '100%' }}>
                  <>
                    <View UNSAFE_className={styles.btn_text}>{t('next', { ns: 'layout_components' })}</View>
                    &nbsp;&nbsp;&nbsp;
                    <ArrowRight className={styles.icon_navigate} />
                  </>
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
      </View>
    );
  };

  const renderContent = () => {
    if (!showDetail) {
      return renderMessageList();
    } else if (isMobile) {
      return renderMessageListDetailMobile();
    } else {
      return renderMessageListDetailLarge();
    }
  };

  const updateNewMessageDialogState = (flag: boolean, reloadMessageList: boolean = false): void => {
    //console.log('updateSearchDialogState param', reqParams);
    setShowNewMessageDialog(flag);
    if (reloadMessageList) {
      listMessageList.reload();
      resetMessageListState('');
    }
  };

  if (showLoader) {
    return <Loader />;
  } else {
    return (
      <>
        {showNewMessageDialog && <NewMessage styles={styles} dialogVisibility={showNewMessageDialog} updateNewMessageDialogState={updateNewMessageDialogState} selectedItem={selectedItem} />}
        {rowsData?.length > 0 ? (
          <div ref={componentRef}>
            {isMobile || (componentRef.current && componentRef.current.offsetWidth < 780) ? (
              renderContent()
            ) : (
              <View width={'100%'} position={'relative'} height={'889px'} UNSAFE_className={styles.message_list_wrapper}>
                <View position={'relative'} top={0} left={0} width={'340px'} height={'100%'} overflow={'hidden'} UNSAFE_className={styles.message_list_parent}>
                  <Flex position={'relative'} top={0} left={0} direction={'column'} alignItems={'start'} justifyContent={'start'}>
                    <View position={'relative'} top={0} left={0} width={'100%'} height={'64px'} overflow={'hidden'} UNSAFE_className={styles.messages_list_header_parent}>
                      <Flex direction={'row'} position={'absolute'} top={24} height={'32px'} alignItems={'center'} justifyContent={'center'} marginStart={12} UNSAFE_className={styles.btn_new_email}>
                        {/* {showNewMessageDialog && <NewMessage styles={styles} store={store} messageService={messageService} dialogVisibility={showNewMessageDialog} />} */}
                        <Button
                          variant="primary"
                          UNSAFE_style={{ background: 'transparent', border: 'none', width: '100%', fontSize: '14px' }}
                          onPress={() => {
                            setShowNewMessageDialog(true);
                          }}
                        >
                          <>
                            <Plus size={18} className={styles.btn_new_email_text} />
                            <span className={styles.btn_new_email_text}>{t('new_email', { ns: 'layout_components' })}</span>
                          </>
                        </Button>
                      </Flex>
                      <Flex position={'absolute'} top={24} left={188} direction={'row'} alignItems={'center'} justifyContent={'start'} gap={15}>
                        <ArrowClockwise className={styles.message_list_header_icon} onClick={reloadMessageList} />
                        {/* <SearchIcon size={24} /> */}
                        <SearchButton />
                        {/* <FilterIcon UNSAFE_className={styles.message_list_header_icon} /> */}
                        <FilterButton />
                        <MoreIcon size={24} />
                      </Flex>
                    </View>
                    <Flex maxHeight="100vh" width={'100%'}>
                      <ListBox
                        autoFocus
                        id="listMessageCards"
                        selectionMode="single"
                        onSelectionChange={handleListRowClick}
                        defaultSelectedKeys={selectedKeys}
                        selectedKeys={selectedKeys}
                        aria-label="Message Cards"
                        items={listMessageList.items}
                        isLoading={listMessageList.isLoading}
                        onLoadMore={totalMessageListRec > rowsData.length ? listMessageList.loadMore : undefined}
                        width={'100%'}
                        UNSAFE_style={{ padding: '0 0 0 5px' }}
                      >
                        {item => (
                          <Item key={item.id}>
                            <CardComponent item={item} />
                          </Item>
                        )}
                      </ListBox>
                    </Flex>
                  </Flex>
                </View>
                <View position={'absolute'} top={0} left={340} width={'1px'} height={'100%'} UNSAFE_className={styles.message_list_divider}></View>
                {showLoaderDetail || listMessageList.isLoading ? (
                  <View position={'absolute'} top={15} left={341} width={'700px'} height={'98%'} overflow={'hidden'} UNSAFE_className={styles.message_list_detail_wrapper}>
                    <Loader />
                  </View>
                ) : (
                  <View
                    position={'absolute'}
                    top={15}
                    left={341}
                    width={{ base: '700px', L: '700px', XXL: '82%' }}
                    height={'98%'}
                    overflow={'hidden'}
                    UNSAFE_className={styles.message_list_detail_wrapper}
                  >
                    <Flex
                      direction={'column'}
                      position={'absolute'}
                      top={0}
                      left={0}
                      width={'100%'}
                      height={'98%'}
                      alignItems={'start'}
                      justifyContent={'start'}
                      UNSAFE_className={styles.message_list_detail_parent}
                    >
                      <Flex direction={'column'} height={'98%'} alignSelf={'stretch'} alignItems={'center'} justifyContent={'start'}>
                        <View position={'relative'} top={0} right={0} width={'100%'} height={'64px'} overflow={'hidden'} UNSAFE_className={styles.message_list_detail_header_parent}>
                          <Flex direction={'row'} position={'absolute'} top={'10px'} left={'10px'} justifyContent={'start'} gap={'18px'} alignItems={'center'}>
                            <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'12px'}>
                              <View>{isMobile && <ArrowLeft size={24} className={styles.message_list_header_icon} />}</View>
                              <Flex direction={'row'} width={'34px'} height={'34px'} flexShrink={0} justifyContent={'center'} UNSAFE_className={styles.action_button}>
                                <ReplyIcon size={24} />
                              </Flex>
                              <Flex direction={'row'} width={'34px'} height={'34px'} flexShrink={0} justifyContent={'center'} UNSAFE_className={styles.action_button}>
                                <ReplyAllIcon size={24} />
                              </Flex>
                              <Flex direction={'row'} width={'34px'} height={'34px'} flexShrink={0} justifyContent={'center'} UNSAFE_className={styles.action_button}>
                                <ArrowForwardIcon size={24} />
                              </Flex>
                            </Flex>
                            <View position={'relative'} width={'1px'} height={'24px'} UNSAFE_className={styles.message_list_detail_header_divider}></View>
                            <Flex direction={'row'} height={'32px'} alignItems={'center'} justifyContent={'center'} width={'100px'} UNSAFE_className={styles.btn_comment}>
                              <button className={styles.btn_comment_text} style={{ background: 'transparent', border: 'none', width: '100%', fontSize: '14px' }}>
                                {t('comment', { ns: 'layout_components' })}
                              </button>
                            </Flex>
                          </Flex>
                          <Flex direction={'row'} position={'absolute'} top={'10px'} right={'40px'} alignItems={'center'} justifyContent={'start'} gap={'10px'}>
                            <RemoveMessageButton />
                            <MoreIcon size={24} />
                          </Flex>
                        </View>
                        <Flex
                          direction={'column'}
                          width={'100%'}
                          height={messageContent.MESSAGE?.ATTACHMENT ? '120px' : '105px'}
                          alignItems={'start'}
                          justifyContent={'start'}
                          UNSAFE_className={styles.email_content_header_parent}
                          UNSAFE_style={{ borderBottom: '1px solid #eceff1' }}
                        >
                          <Flex direction={'row'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} gap={'18px'}>
                            <View UNSAFE_className={styles.user_img}>
                              <img className={styles.avatar_desktop_light} alt="" src="../../../../../../assets/images/avatar--desktop--light@2x.png" />
                            </View>
                            <Flex direction={'column'} width={'100%'} alignItems={'start'} justifyContent={'start'} gap={'5px'}>
                              <Flex direction={'row'} alignSelf={'stretch'} alignItems={'end'} justifyContent={'space-between'}>
                                <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} gap={'8px'}>
                                  <View UNSAFE_className={styles.user_full_name}>{getSenderName(selectedDataRow.sender)}</View>
                                  <View width={'1px'} position={'relative'} height={'12px'} UNSAFE_className={styles.user_detail_divider}></View>
                                  <View UNSAFE_className={styles.user_email}>{getSenderEmail(selectedDataRow.mailFrom)}</View>
                                </Flex>
                                <View position={'relative'} right={'25px'} UNSAFE_className={styles.div_date}>
                                  3-{moment(selectedDataRow.date).format('DD.MM.YYYY HH:mm')}
                                </View>
                              </Flex>
                              <Flex direction={'column'} alignSelf={'stretch'} alignItems={'start'} justifyContent={'start'} gap={'8px'} UNSAFE_className={styles.subject_parent}>
                                <View position={'relative'} alignSelf={'stretch'} UNSAFE_className={styles.email_subject}>
                                  {selectedDataRow.subject}
                                </View>
                                <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'6px'}>
                                  <View UNSAFE_className={styles.address}>{messageContent.MESSAGE?.folderName}</View>
                                  {messageContent.MESSAGE?.projectName && (
                                    <>
                                      <View UNSAFE_className={styles.icon_chevron_right}> {'>'} </View>
                                      <View UNSAFE_className={styles.address}>{messageContent.MESSAGE?.projectName}</View>
                                    </>
                                  )}
                                  {messageContent.MESSAGE?.activityName && (
                                    <>
                                      <View UNSAFE_className={styles.icon_chevron_right}> {'>'} </View>
                                      <View UNSAFE_className={styles.address}>{messageContent.MESSAGE?.activityName}</View>
                                    </>
                                  )}
                                </Flex>
                                <MessageAttachment store={store} styles={styles} messageContent={messageContent} />
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                        <View width={'93%'} position={'relative'} top={'16px'} UNSAFE_className={styles.email_content} height={'75%'}>
                          <div dangerouslySetInnerHTML={{ __html: messageContent.MESSAGE?.PREVIEW?.content }} />
                        </View>
                      </Flex>
                    </Flex>
                    <Flex direction={'column'} position={'absolute'} bottom={'16px'} left={'calc(50% - 128.5px)'} alignItems={'center'} justifyContent={'end'}>
                      <Flex direction={'row'} width={'258px'} alignItems={'center'} justifyContent={'center'} gap={'32px'}>
                        {navigationIndex > 0 && (
                          <Flex direction={'row'} height={'32px'} alignItems={'center'} justifyContent={'center'} gap={'8px'} UNSAFE_className={styles.btn_previous}>
                            <Button variant="primary" onPress={previousNavigation} UNSAFE_style={{ background: 'transparent', border: 'none', width: '100%' }}>
                              <>
                                <ArrowLeft className={styles.icon_navigate} />
                                &nbsp;&nbsp;&nbsp;
                                <View UNSAFE_className={styles.btn_text}>{t('previous', { ns: 'layout_components' })}</View>
                              </>
                            </Button>
                          </Flex>
                        )}
                        {navigationIndex < rowsData.length - 1 && (
                          <Flex direction={'row'} width={'113px'} height={'32px'} flexShrink={0} alignItems={'center'} justifyContent={'center'} gap={'8px'} UNSAFE_className={styles.btn_next}>
                            <Button variant="primary" onPress={nextNavigation} UNSAFE_style={{ background: 'transparent', border: 'none', width: '100%' }}>
                              <>
                                <View UNSAFE_className={styles.btn_text}>{t('next', { ns: 'layout_components' })}</View>
                                &nbsp;&nbsp;&nbsp;
                                <ArrowRight className={styles.icon_navigate} />
                              </>
                            </Button>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  </View>
                )}
              </View>
            )}
          </div>
        ) : (
          <View position={'relative'} top={0} left={0} width={'100%'} height={'100%'} overflow={'hidden'} UNSAFE_className={styles.message_list_parent}>
            <Flex position={'relative'} top={0} left={0} direction={'column'} alignItems={'start'} justifyContent={'start'}>
              <View position={'relative'} top={0} left={0} width={'100%'} height={'64px'} overflow={'hidden'} UNSAFE_className={styles.messages_list_header_parent}>
                <Flex direction={'row'} position={'absolute'} top={24} height={'32px'} alignItems={'center'} justifyContent={'center'} marginStart={12} UNSAFE_className={styles.btn_new_email}>
                  {/* {showNewMessageDialog && <NewMessage styles={styles} store={store} messageService={messageService} dialogVisibility={showNewMessageDialog} />} */}
                  <Button
                    variant="primary"
                    UNSAFE_style={{ background: 'transparent', border: 'none', width: '100%', fontSize: '14px' }}
                    onPress={() => {
                      setShowNewMessageDialog(true);
                    }}
                  >
                    <>
                      <Plus size={18} className={styles.btn_new_email_text} />
                      <span className={styles.btn_new_email_text}>{t('new_email', { ns: 'layout_components' })}</span>
                    </>
                  </Button>
                </Flex>
                <Flex position={'absolute'} top={25} right={15} direction={'row'} alignItems={'center'} justifyContent={'start'} gap={18}>
                  <ArrowClockwise className={styles.message_list_header_icon} onClick={reloadMessageList} />
                  {/* <SearchIcon size={24} /> */}
                  <SearchButton />
                  {/* <FilterIcon UNSAFE_className={styles.message_list_header_icon} /> */}
                  <FilterButton />
                  <MoreIcon size={24} />
                </Flex>
              </View>
            </Flex>
            <NoResultsMessage />
          </View>
        )}
      </>
    );
  }
}

export const MessageListComponent = LayoutComponent(MessageList);
