import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface INewMessageTemplateRequest extends IBaseRequest {
  resultSystemId?: string;
  messageTemplate?: MessageTemplate;
}

export interface MessageTemplate {
  id?: string;
  aclh?: string;
  treeHandle?: string;
  templateName?: string;
  description?: string;
  created?: string;
  subject?: string;
  recipient?: string;
  identity?: string;
  messageContainer?: string;
  messageContainerName?: string;
  messageGroup?: string;
  isDraft?: string;
  html?: string;
  type?: string;
  language?: string;
  isDefault?: string;
  folderId?: string;
  projectId?: string;
  activityId?: string;
  RECIPIENT?: Recipient;
  BODY?: BODYClass;
  NOTIFY?: BODYClass;
  DOCUMENTS?: Documents;
  TRANSLATIONS?: Translations;
  ADVERTISEMENT?: Advertisement;
  JOB_DESCRIPTION_ITEMS?: JobDescriptionItems;
}

function toMessageTemplateXml(messageTemplate: MessageTemplate): string {
  var xml = '';

  if (messageTemplate.id) xml += `<id>${messageTemplate.id}</id>`;
  if (messageTemplate.aclh) xml += `<aclh>${messageTemplate.aclh}</aclh>`;
  if (messageTemplate.treeHandle) xml += `<treeHandle>${messageTemplate.treeHandle}</treeHandle>`;
  if (messageTemplate.templateName) xml += `<templateName>${messageTemplate.templateName}</templateName>`;
  if (messageTemplate.description) xml += `<description>${messageTemplate.description}</description>`;
  if (messageTemplate.created) xml += `<created>${messageTemplate.created}</created>`;
  if (messageTemplate.subject) xml += `<subject>${messageTemplate.subject}</subject>`;
  if (messageTemplate.recipient) xml += `<recipient>${messageTemplate.recipient}</recipient>`;
  if (messageTemplate.identity) xml += `<identity>${messageTemplate.identity}</identity>`;
  if (messageTemplate.messageContainer) xml += `<messageContainer>${messageTemplate.messageContainer}</messageContainer>`;
  if (messageTemplate.messageContainerName) xml += `<messageContainerName>${messageTemplate.messageContainerName}</messageContainerName>`;
  if (messageTemplate.messageGroup) xml += `<messageGroup>${messageTemplate.messageGroup}</messageGroup>`;
  if (messageTemplate.isDraft) xml += `<isDraft>${messageTemplate.isDraft}</isDraft>`;
  if (messageTemplate.html) xml += `<html>${messageTemplate.html}</html>`;
  if (messageTemplate.type) xml += `<type>${messageTemplate.type}</type>`;
  if (messageTemplate.language) xml += `<language>${messageTemplate.language}</language>`;
  if (messageTemplate.isDefault) xml += `<isDefault>${messageTemplate.isDefault}</isDefault>`;
  if (messageTemplate.folderId) xml += `<folderId>${messageTemplate.folderId}</folderId>`;
  if (messageTemplate.projectId) xml += `<projectId>${messageTemplate.projectId}</projectId>`;
  if (messageTemplate.activityId) xml += `<activityId>${messageTemplate.activityId}</activityId>`;
  if (messageTemplate.RECIPIENT) xml += toRecipientXml(messageTemplate.RECIPIENT);
  if (messageTemplate.BODY) xml += toBODYClassXml(messageTemplate.BODY, 'BODY');
  if (messageTemplate.NOTIFY) xml += toBODYClassXml(messageTemplate.NOTIFY, 'NOTIFY');
  if (messageTemplate.DOCUMENTS) xml += toDocumentsXml(messageTemplate.DOCUMENTS);
  if (messageTemplate.TRANSLATIONS) xml += toTranslationsXml(messageTemplate.TRANSLATIONS);
  if (messageTemplate.ADVERTISEMENT) xml += toAdvertisementXml(messageTemplate.ADVERTISEMENT);
  if (messageTemplate.JOB_DESCRIPTION_ITEMS) xml += toJobDescriptionItemsXml(messageTemplate.JOB_DESCRIPTION_ITEMS);

  return `<messageTemplate>${xml}</messageTemplate>`;
}

export interface Advertisement {
  jobDescription?: string;
  requirements?: string;
  offer?: string;
  clientInfo?: string;
  footer?: string;
  contactName?: string;
  contactPhone?: string;
  contactMail?: string;
  priority?: string;
  salaryCurrency?: string;
  salaryMin?: string;
  salaryMax?: string;
  salaryText?: string;
  KEYWORDS?: Keywords;
}

function toAdvertisementXml(advertisement: Advertisement): string {
  var xml = '';
  if (advertisement.jobDescription) xml += `<jobDescription>${advertisement.jobDescription}</jobDescription>`;
  if (advertisement.requirements) xml += `<requirements>${advertisement.requirements}</requirements>`;
  if (advertisement.offer) xml += `<offer>${advertisement.offer}</offer>`;
  if (advertisement.clientInfo) xml += `<clientInfo>${advertisement.clientInfo}</clientInfo>`;
  if (advertisement.footer) xml += `<footer>${advertisement.footer}</footer>`;
  if (advertisement.contactName) xml += `<contactName>${advertisement.contactName}</contactName>`;
  if (advertisement.contactPhone) xml += `<contactPhone>${advertisement.contactPhone}</contactPhone>`;
  if (advertisement.contactMail) xml += `<contactMail>${advertisement.contactMail}</contactMail>`;
  if (advertisement.priority) xml += `<priority>${advertisement.priority}</priority>`;
  if (advertisement.salaryCurrency) xml += `<salaryCurrency>${advertisement.salaryCurrency}</salaryCurrency>`;
  if (advertisement.salaryMin) xml += `<salaryMin>${advertisement.salaryMin}</salaryMin>`;
  if (advertisement.salaryMax) xml += `<salaryMax>${advertisement.salaryMax}</salaryMax>`;
  if (advertisement.salaryText) xml += `<salaryText>${advertisement.salaryText}</salaryText>`;
  if (advertisement.KEYWORDS) xml += toKeywordsXml(advertisement.KEYWORDS);

  return `<ADVERTISEMENT>${xml}</ADVERTISEMENT>`;
}

export interface Keywords {
  ROW: KeywordsRow[];
}

function toKeywordsXml(keywords: Keywords): string {
  var xml = '';
  keywords.ROW.forEach(keyword => {
    xml += toKeywordsRowXml(keyword);
  });
  return `<KEYWORDS>${xml}</KEYWORDS>`;
}

export interface KeywordsRow {
  keyword: string;
}

function toKeywordsRowXml(keyword: KeywordsRow): string {
  var xml = '';
  xml += `<keyword>${keyword.keyword}</keyword>`;
  return `<ROW>${xml}</ROW>`;
}

export interface BODYClass {
  string: string;
}

function toBODYClassXml(bodyClass: BODYClass, type: string): string {
  console.log('bodyClass.string', bodyClass.string);
  var xml = '';
  xml += `<string>${bodyClass.string}</string>`;
  return `<${type}>${xml}</${type}>`;
}

export interface Documents {
  DOCUMENT: Document[];
}

function toDocumentsXml(documents: Documents): string {
  var xml = '';
  documents.DOCUMENT.forEach(document => {
    xml += toDocumentXml(document);
  });
  return `<DOCUMENTS>${xml}</DOCUMENTS>`;
}

export interface Document {
  id: string;
  name?: string;
  size?: string;
}

function toDocumentXml(document: Document): string {
  var xml = '';
  xml += `<id>${document.id}</id>`;
  if (document.name) xml += `<name>${document.name}</name>`;
  if (document.size) xml += `<size>${document.size}</size>`;
  return `<DOCUMENT>${xml}</DOCUMENT>`;
}
export interface JobDescriptionItems {
  ROW: JobDescriptionItemsRow[];
}

function toJobDescriptionItemsXml(jobDescriptionItems: JobDescriptionItems): string {
  var xml = '';
  jobDescriptionItems.ROW.forEach(item => {
    xml += toJobDescriptionItemsRowXml(item);
  });
  return `<JOB_DESCRIPTION_ITEMS>${xml}</JOB_DESCRIPTION_ITEMS>`;
}

export interface JobDescriptionItemsRow {
  id?: string;
  type?: string;
  valueId?: string;
  value?: string;
  parent?: string;
}

function toJobDescriptionItemsRowXml(item: JobDescriptionItemsRow): string {
  var xml = '';
  if (item.id) xml += `<id>${item.id}</id>`;
  if (item.type) xml += `<type>${item.type}</type>`;
  if (item.valueId) xml += `<valueId>${item.valueId}</valueId>`;
  return `<ROW>${xml}</ROW>`;
}

export interface Recipient {
  TO?: ContactInfo[];
  CC?: ContactInfo[];
  BCC?: ContactInfo[];
  USER?: ContactInfo[];
}

function toRecipientXml(recipient: Recipient): string {
  var xml = '';
  if (recipient.TO)
    recipient.TO.forEach(contact => {
      xml += toContactInfoXml(contact, 'TO');
    });
  if (recipient.CC)
    recipient.CC.forEach(contact => {
      xml += toContactInfoXml(contact, 'CC');
    });
  if (recipient.BCC)
    recipient.BCC.forEach(contact => {
      xml += toContactInfoXml(contact, 'BCC');
    });
  if (recipient.USER)
    recipient.USER.forEach(contact => {
      xml += toContactInfoXml(contact, 'USER');
    });
  return `<RECIPIENT>${xml}</RECIPIENT>`;
}

export interface ContactInfo {
  CONTENT?: string;
  salutation?: string;
  contact?: string;
  id?: string;
}

function toContactInfoXml(contact: ContactInfo, type: string): string {
  var xml = '';
  if (contact.CONTENT) xml += `<CONTENT>${contact.CONTENT}</CONTENT>`;
  if (contact.salutation) xml += `<salutation>${contact.salutation}</salutation>`;
  if (contact.contact) xml += `<contact>${contact.contact}</contact>`;
  if (contact.id) xml += `<id>${contact.id}</id>`;
  return `<${type}>${xml}</${type}>`;
}

export interface Translations {
  TRANSLATION: Translation[];
}

function toTranslationsXml(translations: Translations): string {
  var xml = '';
  translations.TRANSLATION.forEach(translation => {
    xml += toTranslationXml(translation);
  });
  return `<TRANSLATIONS>${xml}</TRANSLATIONS>`;
}
export interface Translation {
  id?: string;
  name?: string;
  isDefault?: string;
  language?: string;
}

function toTranslationXml(translation: Translation): string {
  var xml = '';
  if (translation.id) xml += `<id>${translation.id}</id>`;
  if (translation.name) xml += `<name>${translation.name}</name>`;
  if (translation.isDefault) xml += `<isDefault>${translation.isDefault}</isDefault>`;
  if (translation.language) xml += `<language>${translation.language}</language>`;
  return `<TRANSLATION>${xml}</TRANSLATION>`;
}

export class NewMessageTemplateRequest extends BaseRequest implements INewMessageTemplateRequest {
  public resultSystemId?: string;
  public messageTemplate?: MessageTemplate;

  constructor(server: string, session: string, otherOptions: any) {
    super(server, session);
    this.resultSystemId = otherOptions.resultSystemId;
    this.messageTemplate = otherOptions.messageTemplate;
  }

  public toXml(): string {
    var parameters: string = super.toXml();
    if (this.messageTemplate) parameters += toMessageTemplateXml(this.messageTemplate);
    return super.wrapperXml('NewMessageTemplate', parameters);
  }
}
