import { ToastQueue } from "@adobe/react-spectrum";
import { AtollonEvent, publish } from "../infra/events/Events";
import { BrowserStorage } from "../services/LocalStorage/BrowserStorage";
import { IStore } from "../services/LocalStorage/IStore";
import { Application } from "../services/soap/features/responses/ListApplicationsResponse";

export const getAppIndent = (applications:Application[], id: string) => {
    var selected:Application[] = applications.filter((x) => x.id === id);
    return selected[0].appIdent;
};

export const getAtollonUrl = (appIdent: string | null):string => {
    if(!appIdent)
        return ''; 

    const store:IStore = new BrowserStorage();
    const prefix:string = 'atollon://';

    const customApps:string[] = store.customApps;
    const appUrls:Map<string, string> = new Map([
        ['com.atollon.messaging.messages', 'messaging/openCommunication'],
        ['com.atollon.finance.invoice.issued', 'finance/openInvoicesIssued'],
        ['com.atollon.finance.invoice.received', 'finance/openInvoicesReceived'],
        ['com.atollon.scheduler.callCenter.outgoing', 'scheduler/openCallCenter'],
        ['com.atollon.scheduler.events', 'scheduler/openCalendar'],
        ['com.atollon.scheduler.tasks', 'scheduler/openTasks'],
        ['com.atollon.lagoon.general.customApplication.serviceActivities', 'timesheet/openTimesheetGrid'],
        ['com.atollon.recruitment.dashboard', 'recruitment/openRecruitmentDashboard'],
        ['com.atollon.recruitment.foldertype.applicant', 'recruitment/openApplicants'],
        ['com.atollon.recruitment.vacancies', 'recruitment/openVacancies'],
        // ['', 'logger/show'],
        // ['', 'access/openUsersSettings']
    ]);


    if(customApps.includes(appIdent))
        return prefix  + 'customApplication/' + appIdent;

    if(appUrls.has(appIdent))
        return prefix + appUrls.get(appIdent);

    return '';
}

export const invokeFinderApp = (contextId: string, contextType: string = ''):void => {
    publish(AtollonEvent.FINDER_DETAIL_LOADED, { contextId, contextType });
}

export const invokeCreateContextApp = (contextId: string, contextType: string = '', isCopy: boolean = false):void => {
    publish(AtollonEvent.CREATE_CONTEXT_APP, { contextId, contextType, isCopy });
}

export const openPdfContentInNewWindow = (content: any): void => {
    // Convert binary PDF data to blob and create URL
    const blob = new Blob([content], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    
    // Create a temporary iframe to handle the PDF
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    
    // Open in new window with proper Safari handling
    const windowReference = window.open('', '_blank', 'scrollbars=yes,status=yes,menubar=no,toolbar=no');
    
    if (windowReference) {
        // Set the content to the new window
        windowReference.location.href = blobUrl;
        
        // Clean up blob URL after a delay to ensure content is loaded
        setTimeout(() => {
            URL.revokeObjectURL(blobUrl);
            document.body.removeChild(iframe);
        }, 1000); // 1 second delay to ensure content is loaded
    } else {
        // Fallback for browsers that block popups
        iframe.src = blobUrl;
        iframe.style.display = 'block';
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.position = 'fixed';
        iframe.style.top = '0';
        iframe.style.left = '0';
        iframe.style.zIndex = '9999';
        
        // Add close button
        const closeButton = document.createElement('button');
        closeButton.innerHTML = '×';
        closeButton.style.position = 'fixed';
        closeButton.style.top = '10px';
        closeButton.style.right = '10px';
        closeButton.style.zIndex = '10000';
        closeButton.style.padding = '5px 10px';
        closeButton.style.cursor = 'pointer';
        closeButton.onclick = () => {
            document.body.removeChild(iframe);
            document.body.removeChild(closeButton);
            URL.revokeObjectURL(blobUrl);
        };
        document.body.appendChild(closeButton);
    }
}

export const openUrlInNewWindow = (url: string):void => {
    try {
        // Attempt to open the window
        window.open(url, '_blank', 'scrollbars=yes,status=yes,menubar=no,toolbar=no');
    } catch (error) {
        console.error('Error opening document:', error);
        ToastQueue.negative('Failed to open document. Please try again or check your browser settings.', 
            { timeout: 3000 }
        );
    }
}
